import { createApp } from 'vue'
import Widget from './Widget.vue'

export function init({
  element,
  source,
  primaryColor,
  buttonText,
  inputPlaceholderText,
}) {
  if (!element) throw new Error('element option was not provided')
  if (!source) throw new Error('source option was not provided')
  return createApp(Widget, {
    source,
    primaryColor,
    buttonText,
    inputPlaceholderText,
  }).mount(element)
}
