<template>
  <div class="flex shadow-sm rounded-lg">
    <div class="relative w-full">
      <input
        type="text"
        class="w-full h-full py-2.5 px-3 focus:outline-none border-l border-t border-b focus:border-deepsea-medium rounded-l-lg focus:ring-deepsea-medium"
        v-model="addressText"
        :placeholder="inputPlaceholderText"
        @focus="onFocus"
        @blur="onBlur"
        @input="onAddressInput"
      />
      <div
        v-if="open"
        class="dropdown absolute border-l border-r border-b rounded-b-lg bg-white"
      >
        <div
          :key="item.displayText"
          class="cursor-pointer hover:bg-gray-100 py-1 px-2"
          v-for="item in addresses"
          @click="onClickAddress(item)"
        >
          <span class="text-sm !text-left">{{ item.displayText }}</span>
        </div>
      </div>
    </div>
    <button
      :class="[
        'w-[225px] relative items-center space-x-2 rounded-r-md border px-4 py-3 text-sm font-bold text-white focus:outline-none focus:ring-1 text-center',
        {
          'focus:ring-deepsea-medium hover:border-deepsea-full hover:bg-opacity-75':
            !!primaryColor,
          'border-deepsea-full hover:border-deepsea-full/75 bg-deepsea-full hover:bg-deepsea-full/75 focus:border-deepsea-full focus:ring-deepsea-medium':
            !primaryColor,
        },
      ]"
      @click="onCreateQuote"
      :style="{ backgroundColor: primaryColor, borderColor: primaryColor }"
    >
      {{ buttonText ? buttonText : "Instant Flood Insurance Quote" }}
    </button>
  </div>
</template>

<script>
import "./assets/tailwind.css";
import { lookupAddress } from "./address-lookup";
import qs from "querystring";
import { DTC_ENVIRONMENT } from "./config";

export default {
  data() {
    return {
      timeout: null,
      suggestions: [],
      selectedSuggestion: null,
      addressText: "",
      open: false,
      error: null,
    };
  },
  props: {
    buttonText: String,
    primaryColor: String,
    inputPlaceholderText: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    addresses() {
      if (!this.suggestions || !this.suggestions.length) {
        return [];
      }

      return this.suggestions?.map((s) => {
        let whiteSpace = "";
        if (s.secondary) {
          if (s.entries > 1) {
            s.secondary += " (" + s.entries + " entries)";
          }
          whiteSpace = " ";
        }

        const result =
          s.street_line +
          whiteSpace +
          s.secondary +
          " " +
          s.city +
          ", " +
          s.state +
          " " +
          s.zipcode;
        return {
          ...s,
          displayText: result,
        };
      });
    },
  },
  methods: {
    onClickAddress(suggestion) {
      this.selectedSuggestion = suggestion;
      this.addressText = this.selectedSuggestion.displayText;
      this.open = false;
    },
    getQueryStringParams() {
      if (!this.selectedSuggestion) {
        return qs.stringify({
          address: this.addressText,
          "property-type": "primaryHome",
          "product-type": "residential",
          source: this.source,
        });
      }

      // eslint-disable-next-line no-unused-vars
      const { displayText, entries, ...rest } = this.selectedSuggestion;

      return qs.stringify({
        ...rest,
        "property-type": "primaryHome",
        "product-type": "residential",
        source: this.source,
      });
    },
    onCreateQuote() {
      const url = `https://${DTC_ENVIRONMENT}/consumer-app/?${this.getQueryStringParams()}`;
      window.open(url, "_new");
    },
    onFocus() {
      this.open = this.addresses.length;
    },
    onBlur() {
      setTimeout(() => {
        this.open = false;
      }, 200);
    },
    onAddressInput() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const self = this;
      this.timeout = setTimeout(async () => {
        const result = await lookupAddress(this.addressText);
        self.suggestions = result.suggestions;
        self.open = true;
      }, 350);
    },
  },
  async mounted() {
    if (!this.source) {
      this.error = `Source parameter is missing.`;
    }
  },
};
</script>

<style scoped>
.dropdown {
  left: 0px;
  right: 0px;
}
</style>
