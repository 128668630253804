/* eslint-disable no-unused-vars */
import axios from "axios";

const ENDPOINT_BASE = process.env.VUE_APP_LOOKUP_ENVIRONMENT;

export async function lookupAddress(addr) {
  const url = new URL(`${ENDPOINT_BASE}/address-lookup/autocomplete`);
  url.searchParams.set("search", addr);
  url.searchParams.set("max_results", 10);
  url.searchParams.set("prefer_geolocation", "city");
  const response = await axios.get(url);

  return response.data;
}
